.selected-color {
    background-color: rgb(216 180 254);
}
.monitored-color {
    background-color: #fffaeb;
}

.monitored-label-color {
    color: #f5a623;
    font-size: 10px;
}

.color-dot-hard,
.color-dot-link,
.color-dot-soft,
.color-dot-default {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.color-dot-hard {
    background-color: rgb(173, 173, 173);
}
.color-dot-link {
    background-color: blue;
}
.color-dot-soft {
    background-color: rgb(198, 142, 236);
}
.color-dot-default {
    background-color: black;
}

.ant-table-wrapper .ant-table-sticky-scroll-bar {
    height: 18px;
    background-color: #4e5255;
}
