/* special table */
#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
/* keys */
#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table th {
    display: block;
    width: 300px;
    height: 100px;
}
/* values */
#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table td {
    display: block;
    width: 500px;
    height: 100px;
}

#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table tr {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table {
    display: flex;
}

#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table thead,
#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table tbody {
    display: block;
}

#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table thead th {
    display: block;
}

#root > section > section > main > div > div > div > div.ant-page-header-content > div > form > div > div > div > div > div > div > table tbody tr {
    display: block;
}

#root
    > section
    > section
    > main
    > div
    > div
    > div
    > div.ant-page-header-content
    > div
    > form
    > div
    > div
    > div
    > div
    > div
    > div
    > table
    tbody
    tr
    td {
    display: block;
    width: 100%;
}

